import { Field } from '@wsb_dev/datafi-shared/lib/types';
import { DialogController } from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { flattenFields } from '@wsb_dev/datafi-shared/lib/util/surveys/flattenFields';
import { AlertService } from 'services/util/Alert';

export interface editSelection {
    column: string,
    value: boolean
}
@autoinject

export class BatchFieldEdit {

    flatrows: Field[] = [];
    rows: Field[] = [];
    sort: number;
    selection = {
        column: undefined,
        value: undefined,
    } as editSelection;
    customFieldSelection: boolean;

    constructor(
        private dialogController: DialogController,
        private alerts: AlertService,
    ) { }

    activate(data: { model: Field[], fieldSelection?: Array<string>, customFieldSelection?: boolean }) {
        this.customFieldSelection = data?.customFieldSelection;
        this.rows = data.model;
        flattenFields(data.model, null, {preserveParent: true}).forEach((field) => {
            const flatField = {
                name: field.name,
                label: field.label,
                type: field.type,
                path: field.path,
                ...field.visibility,
            };

            if (data?.fieldSelection) {
                if (data?.fieldSelection.includes(field.path)) {
                    flatField['list'] = true;
                    flatField['edit'] = true;
                    flatField['filter'] = true;
                } else {
                    flatField['list'] = false;
                    flatField['edit'] = false;
                    flatField['filter'] = false;
                }
            }

            this.flatrows.push(flatField);
        });
    }

    onSelectionChanged(selectAll: boolean) {
        this.flatrows.forEach((row) => {
            selectAll ? row.checked = true : row.checked = false;
        });
        this.flatrows = [...this.flatrows];
    }

    updateValue(hideRepeatedAndSystemFields?: boolean) {
        if (hideRepeatedAndSystemFields) {
            this.flatrows.forEach((row) => {
                if (!row.path.includes('metadata.')) {
                    row['list'] = false;
                    row['edit'] = false;
                    row['filter'] = false;
                }
            });
        } else {
            if (this.selection.value === undefined) {
                return;
            }

            this.flatrows.forEach((row) => {
                if (row.checked) {
                    row[this.selection.column] = this.selection.value;
                }
            });
        }

        this.flatrows = [...this.flatrows];
    }

    handleSort(col: string): void {
        this.sort === 1 ? this.sort = -1 : this.sort = 1;
        this.flatrows.sort(
            (a: { name: string }, b: { name: string }) => {
                const nameA = (a[col]).toString().toUpperCase();
                const nameB = (b[col]).toString().toUpperCase();

                if (this.sort === 1) {
                    // asc, arrow up
                    if (nameA < nameB) { return -1; }
                    if (nameA > nameB) { return 1; }
                    return 0;
                } else {
                    // desc, arrow down
                    if (nameA > nameB) { return -1; }
                    if (nameA < nameB) { return 1; }
                    return 0;
                }
            },
        );
    }

    submit() {
        if (this.flatrows.filter((row) => row.list).length > 15 && this.customFieldSelection) {
            return this.alerts.create({
                label: 'You can only select up to 15 fields',
                level: 'error',
                dismissable: true,
            });
        }
        this.applyAllEdits(this.rows, '', '');
        this.dialogController.ok(this.rows);
    }

    cancel() {
        this.removePath(this.rows);
        this.dialogController.cancel(this.rows);
    }

    removePath(rows) {
        rows.forEach((item) => {
            if (item.type === 'object') {
                delete item.path;
                this.removePath(item.fields);
            } else if (item.type === 'array') {
                delete item.path;
                this.removePath(item.fields);
            }
            delete item.path;
        });
    }

    applyAllEdits(obj: Field[], parent: string, child: string) {
        obj.forEach((item) => {
            child = item.name;
            if (item.type === 'object') {
                delete item.path;
                const newParent = parent.concat(item.name + '.');
                this.applyAllEdits(item.fields, newParent, child);
            } else if (item.type === 'array') {
                delete item.path;
                const newParent = parent.concat(item.name + '.');
                this.applyAllEdits(item.fields, newParent, child);
            } else if ('name' in item) {
                const getPath = this.flatrows.find((flatrow) =>
                    flatrow.path === parent.concat(child) ||
                    (parent.includes('metadata') && flatrow.path === child),
                );
                if (getPath) {
                    item.visibility.filter = getPath.filter;
                    item.visibility.list = getPath.list;
                    item.visibility.edit = getPath.edit;
                }
            }
        });
    }
}
